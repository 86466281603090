import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Segment, Tab } from 'semantic-ui-react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Select from 'react-select'
import { TagsInput } from 'react-tag-input-component'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ProgressBar from 'react-bootstrap/ProgressBar'
import {
  getAssignedPhotographers,
  getImagesToTag,
  setImageVerifiedFlag,
  setImagesTaggingDetails,
} from '../actions/faceActions'
import {
  BsFillArrowRightSquareFill,
  BsFillArrowLeftSquareFill,
} from 'react-icons/bs'
import './TagFaces.css'
import '../../shared/components/ProgressBar.css'
import Spinner from '../../shared/components/Spinner'
import { useCallback } from 'react'
import KeyHandler from '../../shared/components/KeyHandler'

function TagFaces() {
  const { event } = useSelector((state) => state.eventReducer)
  const { assignedPhotographers, assignedPhotographs, taggingFilter, loading } =
    useSelector((state) => state.faceReducer)
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { role, user } = auth
  const [bibNumbers, setBibNumbers] = useState([])
  const [isImagesValid, setIsImagesValid] = useState([])
  const [currentImageBibs, setCurrentImageBibs] = useState([])
  const [currentImageId, setCurrentImageId] = useState('')
  const [verifiedImageId, setVerifiedImageId] = useState('')
  const [isCurrentImageInvalid, setIsCurrentImageInvalid] = useState(false)
  const [selectedPhotographer, setSelectePhotographer] = useState('')
  const [selectedPhotographerName, setSelectePhotographerName] = useState('')
  const [isSearchable, setIsSearchable] = useState(true)
  const [current, setCurrent] = useState(0)
  const [length, setLength] = useState(0)
  const [taggedImages, setTaggedImages] = useState([])
  const [verifiedImages, setVerifiedImages] = useState(() => new Set())
  const [slides, setSlides] = useState([])
  const arrowRightPressed = KeyHandler('ArrowRight')
  const arrowLeftPressed = KeyHandler('ArrowLeft')

  useEffect(() => {
    if (arrowRightPressed) {
      nextSlide()
    }
  }, [arrowRightPressed])

  useEffect(() => {
    if (arrowLeftPressed) {
      prevSlide()
    }
  }, [arrowLeftPressed])

  useEffect(() => {
    if (taggingFilter) {
      setIsImagesValid([])
      setBibNumbers([])
      setSelectePhotographer('')
      setCurrent(0)
      setLength(0)
      setSlides([])
    }
  }, [dispatch, taggingFilter, setSlides])

  useEffect(() => {
    if (event && user && role) {
      if (role === 'ADMIN' || role == 'EVENT_COORDINATOR') {
        dispatch(getAssignedPhotographers(event.id, null))
      } else {
        dispatch(getAssignedPhotographers(event.id, user.id))
      }
    }
  }, [dispatch, event, user, role])

  useEffect(() => {
    if (selectedPhotographer.length > 0 && taggingFilter) {
      dispatch(getImagesToTag(event.id, selectedPhotographer, taggingFilter))
    }
  }, [dispatch, event, selectedPhotographer, taggingFilter])

  useEffect(() => {
    if (assignedPhotographs) {
      setLength(assignedPhotographs.length)
      setSlides(assignedPhotographs)
      let bibNums = [...bibNumbers]
      let imageValidVals = [...isImagesValid]
      let taggedImageList = [...taggedImages]
      assignedPhotographs.map((photograph) => {
        bibNums.push({ id: photograph.id, value: photograph.bibs })
        imageValidVals.push({ id: photograph.id, value: photograph.is_invalid })
        if (photograph.bibs.length > 0 || photograph.is_invalid === true) {
          taggedImageList.push(photograph.id)
        }
      })
      setBibNumbers(bibNums)
      setIsImagesValid(imageValidVals)
      setTaggedImages(taggedImageList)
    }
  }, [
    dispatch,
    setLength,
    setSlides,
    setBibNumbers,
    setIsImagesValid,
    assignedPhotographs,
  ])

  useEffect(() => {
    if (selectedPhotographer && taggingFilter) {
      dispatch(getImagesToTag(event.id, selectedPhotographer, taggingFilter))
    }
  }, [dispatch, event, selectedPhotographer, taggingFilter])

  const onChange = (e) => {
    setSelectePhotographer(e.value)
    setSelectePhotographerName(e.label)
  }

  const handleBibChange = (bibs, imageId) => {
    setCurrentImageId(imageId)
    setCurrentImageBibs(bibs)
    let values = [...bibNumbers]
    const objIndex = values.findIndex((obj) => obj.id === imageId)
    if (objIndex > -1) {
      values[objIndex] = { id: imageId, value: bibs }
    } else {
      values.push({ id: imageId, value: bibs })
    }
    setBibNumbers(values)
    handleTaggedImagesOnBibChange(imageId, bibs)
  }

  const handleValidImageCheck = (event, imageId) => {
    setCurrentImageId(imageId)
    let values = [...isImagesValid]
    setIsCurrentImageInvalid(event.target.checked)
    const objIndex = values.findIndex((obj) => obj.id === imageId)
    if (objIndex > -1) {
      values[objIndex] = { id: imageId, value: event.target.checked }
    } else {
      values.push({ id: imageId, value: event.target.checked })
    }
    setIsImagesValid(values)
    handleTaggedImagesOnImageValidCheck(imageId, event.target.checked)
  }

  const handleTaggedImagesOnBibChange = (imageId, bibs) => {
    let values = [...taggedImages]
    const objIndex = values.findIndex((obj) => obj === imageId)

    if (bibs.length === 0) {
      if (objIndex > -1) {
        values = values.filter((item, index) => index !== objIndex)
      }
    } else {
      if (objIndex > -1) {
        values[objIndex] = imageId
      } else {
        values.push(imageId)
      }
    }
    setTaggedImages(values)
  }

  const handleTaggedImagesOnImageValidCheck = (imageId, isInvalid) => {
    let values = [...taggedImages]
    const objIndex = values.findIndex((obj) => obj === imageId)
    if (isInvalid === true) {
      if (objIndex > -1) {
        values[objIndex] = imageId
      } else {
        values.push(imageId)
      }
    } else {
      if (objIndex > -1) {
        values = values.filter((item, index) => index !== objIndex)
      }
    }
    setTaggedImages(values)
  }

  const addVerifiedImage = (imageId) => {
    setVerifiedImages((prev) => new Set(prev).add(imageId))
  }

  const updateTagging = () => {
    if (currentImageId.length != 0) {
      //handleTaggedImages(currentImageId)
      dispatch(
        setImagesTaggingDetails(
          currentImageId,
          currentImageBibs,
          isCurrentImageInvalid,
          event.id
        )
      )
    }
    if (taggingFilter === 'VERIFY') {
      if (verifiedImageId.length != 0) {
        addVerifiedImage(verifiedImageId)
        dispatch(setImageVerifiedFlag(verifiedImageId))
      }
    }

    setCurrentImageId('')
    setCurrentImageBibs('')
    setIsCurrentImageInvalid(false)
    setVerifiedImageId('')
  }

  const nextSlide = () => {
    updateTagging()
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    updateTagging()
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  const callbackRef = useCallback((inputElement) => {
    if (inputElement) {
      let bibField = inputElement.childNodes[0].lastElementChild
      bibField.setAttribute('tabIndex', '2')
      bibField.focus()
      if (taggingFilter === 'VERIFY') {
        setVerifiedImageId(bibField.getAttribute('name'))
      }
    }
  }, [])

  return (
    <div key={1}>
      <h2 className='medium text-primary'>
        {taggingFilter === 'ALL' ? (
          <>Tag Images - {event.name}</>
        ) : taggingFilter === 'TAGGED' ? (
          <>Tagged Images - {event.name} </>
        ) : taggingFilter === 'UNTAGGED' ? (
          <>Pending Images - {event.name} </>
        ) : taggingFilter === 'VERIFY' ? (
          <>Verify Tagged Images - {event.name} </>
        ) : taggingFilter === 'VERIFIED' ? (
          <>Verified Tagged Images - {event.name} </>
        ) : taggingFilter === 'INVALID' ? (
          <>Invalid Images - {event.name} </>
        ): taggingFilter === 'FILTERED_VALID' ? (
          <>Valid Images Predictedin the Invalid Image List - {event.name} </>
        ): (
          <>Invalid images remaining after predictions - {event.name} </>
        )}
      </h2>

      {loading ? (
        <Spinner />
      ) : (
        <>
          <div key={2}>
            <Segment
              key={1}
              style={{
                width: '100%',
                height: '200px',
              }}>
              {assignedPhotographers ? (
                <div>
                  Select Photographer
                  <Select
                    className='basic-single'
                    classNamePrefix='select'
                    isSearchable={isSearchable}
                    name='photographer'
                    id='photographer'
                    options={assignedPhotographers}
                    onChange={(event) => onChange(event)}
                  />
                </div>
              ) : (
                <></>
              )}
            </Segment>
          </div>
          <Fragment key={3}>
            <Tab.Pane>
              {slides ? (
                slides.length > 0 ? (
                  <div
                    style={{
                      display: 'block',
                      width: '100%',
                    }}>
                    <h3>Selected Photographer : {selectedPhotographerName}</h3>
                    <div>
                      <h3> Tagging Progress: </h3>
                      {taggingFilter === 'VERIFY' ? (
                        <ProgressBar
                          variant='info'
                          now={`${Math.round(
                            (verifiedImages.size / length) * 100
                          )}`}
                          label={`${verifiedImages.size} / ${length} `}
                        />
                      ) : (
                        <ProgressBar
                          variant='info'
                          now={`${Math.round(
                            (taggedImages.length / length) * 100
                          )}`}
                          label={`${taggedImages.length} / ${length} `}
                        />
                      )}
                    </div>
                    <div className='slider'>
                      <div>
                        <BsFillArrowLeftSquareFill
                          style={{
                            background: 'black',
                            color: 'white',
                            borderRadius: '10px',
                          }}
                          className='left-arrow'
                          onClick={prevSlide}
                          tabIndex='1'
                        />
                        <BsFillArrowRightSquareFill
                          style={{
                            background: 'black',
                            color: 'white',
                            borderRadius: '10px',
                          }}
                          className='right-arrow'
                          onClick={nextSlide}
                          tabIndex='4'
                        />
                        {slides ? (
                          slides.map((photograph, index) => (
                            <div
                              className={
                                index === current ? 'slide active' : 'slide'
                              }
                              key={index}
                              id={index}>
                              {index === current && (
                                <LazyLoadImage
                                  key={index}
                                  id={photograph.id}
                                  src={photograph.stripped_path}
                                  alt={photograph.id}
                                  className={
                                    photograph.type === 'LANDSCAPE'
                                      ? 'form-group landscape-image'
                                      : photograph.type === 'PORTRAIT'
                                      ? 'form-group portrait-image'
                                      : 'form-group landscape-image'
                                  }
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                />
                              )}
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className='bib-block'>
                      {slides.map((photograph, index) => (
                        <>
                          {index === current && (
                            <div
                              className='bib-block'
                              key={index}
                              ref={callbackRef}>
                              <TagsInput
                                className={
                                  index === current ? 'slide active' : 'slide'
                                }
                                key={photograph.id}
                                id={photograph.id}
                                value={
                                  bibNumbers.filter(
                                    (obj) => obj.id === photograph.id
                                  )[0]['value']
                                }
                                onChange={(e) =>
                                  handleBibChange(e, photograph.id)
                                }
                                name={photograph.id}
                              />
                              <em className={'bib-text'}>
                                Type each BIB number and press enter to add tags
                              </em>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                    <div className='invalid-images'>
                      {slides.map((photograph, index) => (
                        <>
                          {index === current && (
                            <div key={index}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      className={
                                        index === current
                                          ? 'slide active'
                                          : 'slide'
                                      }
                                      id={photograph.id}
                                      name='isValid'
                                      tabIndex='3'
                                      // checked={isImagesValid[photograph.id]}
                                      checked={
                                        isImagesValid.filter(
                                          (obj) => obj.id === photograph.id
                                        )[0]['value']
                                      }
                                      onChange={(e) =>
                                        handleValidImageCheck(e, photograph.id)
                                      }></Checkbox>
                                  }
                                  label='Bib not found'
                                />
                              </FormGroup>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                ) : (
                  <>Images Not Found</>
                )
              ) : (
                <></>
              )}
            </Tab.Pane>
          </Fragment>
        </>
      )}
    </div>
  )
}
export default TagFaces
