import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Card, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { cleanTaggedFaces, setTaggingFilter } from '../../actions/faceActions'

const InvalidImagesCard = (event) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(cleanTaggedFaces())
    dispatch(setTaggingFilter('INVALID'))
  }

  return (
    <Card>
      <Card.Content>
        <Card.Header>All Invalid Images</Card.Header>
        <Card.Meta>All Invalid Images </Card.Meta>
        <Icon.Group size='large'>
          <Icon name='users' />
        </Icon.Group>
        <Card.Description>View Invalid Images </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className='ui two buttons'>
          <Button basic color='green' onClick={handleClick}>
            <Link
              to={`/event/${event.id}/tagging/tag-faces`}
              className='btn btn-light'>
              View Invalid Images
            </Link>
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

export default InvalidImagesCard
