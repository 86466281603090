import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import { Card } from "semantic-ui-react"
import ImageTaggingCard from "./cards/ImageTaggingCard"
import InvalidImagesCard from "./cards/InvalidImagesCard"
import TaggedImagesCard from "./cards/TaggedImagesCard"
import UntaggedImagesCard from "./cards/UntaggedImagesCard"
import VerifyTaggedImagesCard from "./cards/VerifyTaggedImagesCard"
import VerifiedTaggedImagesCard from "./cards/VerifiedTaggedImagesCard"
import InvalidImagesFilteredCard from "./cards/InvalidmagesFilteredCard"
import ValidImagesFilteredCard from "./cards/ValidImagesFilteredCard"


const TaggingScreen = () => {
  const { event } = useSelector((state) => state.eventReducer)

  return (
    <Fragment>
      <h1 className="large text-primary">Manage Tagging - {event.name}</h1>
      <Fragment>
        <Card.Group>
          <ImageTaggingCard {...event} />
          <UntaggedImagesCard {...event} />
          <TaggedImagesCard {...event} />
          <VerifyTaggedImagesCard {...event} />
          <VerifiedTaggedImagesCard {...event} />
          <InvalidImagesCard {...event} />
          <ValidImagesFilteredCard {...event} />
          <InvalidImagesFilteredCard {...event} />
          {/* <TaggingStatisticsCard {...event} /> */}
          {/* {!loading &&
            isAuthenticated &&
            (role == 'ADMIN'
              ? adminCards
              : role == 'EVENT_COORDINATOR'
              ? adminCards
              : contractorCards)} */}
        </Card.Group>
      </Fragment>
    </Fragment>
  )
}

export default TaggingScreen
